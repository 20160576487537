@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.problems-container {
  padding: 20px;
}

.problems-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
  padding: 1rem;
}

/* Problem Card Styles */
.problem-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 400px;
}

.problem-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.card-image-wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background: #f8f9fa;
  overflow: hidden;
}

.problem-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.problem-card:hover .problem-image {
  transform: scale(1.05);
}

.card-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.difficulty-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.difficulty-badge.easy { background: #28a745; color: white; }
.difficulty-badge.medium { background: #ffc107; color: #212529; }
.difficulty-badge.hard { background: #dc3545; color: white; }

.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
}

.status-badge.pending {
  background: #ff9800;
  color: white;
}

.card-content {
  padding: 1.5rem;
}

.card-header {
  margin-bottom: 12px;
}

.card-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px 0;
  color: #2c3e50;
}

.problem-id {
  color: #6c757d;
  font-size: 12px;
}

.card-meta {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}

.setter-name {
  font-size: 14px;
  color: #495057;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.setter-name i {
  color: #6c757d;
  font-size: 12px;
}

.card-actions {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.primary-actions, 
.secondary-actions {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.card-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  font-size: 14px;
}

.card-btn i {
  font-size: 14px;
}

.card-btn.info { background: #E3F2FD; color: #1976D2; }
.card-btn.edit { background: #E8F5E9; color: #388E3C; }
.card-btn.view { background: #F3E5F5; color: #7B1FA2; }
.card-btn.delete { background: #FFEBEE; color: #D32F2F; }
.card-btn.approve { background: #E8F5E9; color: #388E3C; }
.card-btn.map { background: #FFF3E0; color: #E64A19; }

.card-btn:hover {
  transform: translateY(-2px);
  -webkit-filter: brightness(0.95);
          filter: brightness(0.95);
}

/* Collapse Panels */
.collapse-panel {
  margin-top: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .problems-grid {
    grid-template-columns: 1fr;
  }
  
  .card-actions {
    flex-direction: column;
  }
  
  .primary-actions,
  .secondary-actions {
    flex-wrap: wrap;
  }
}

/* Dashboard Layout */
.dashboard-container {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
}

.dashboard-content {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

/* Action Panel */
.action-panel {
  display: flex;
  justify-content: space-betwenen;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  margin-bottom: 2rem;
}

.main-actions {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.action-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.action-button i {
  font-size: 1.1rem;
}

.action-button.primary {
  background: #4CAF50;
  color: white;
}

.action-button.secondary {
  background: #2196F3;
  color: white;
}

.action-button.info {
  background: #9C27B0;
  color: white;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Language Toggle */
.language-toggle {
  position: relative;
}

.toggle-switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.toggle-switch-label {
  font-size: 0.95rem;
  color: #333;
  margin-bottom: 0;
  font-weight: 500;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-left: 200px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  margin: 0;
}

.toggle-track {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e0e0e0;
  border-radius: 24px;
  transition: background-color 0.3s ease;
}

.toggle-indicator {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.toggle-switch input:checked + label .toggle-track {
  background-color: #2196F3;
}

.toggle-switch input:checked + label .toggle-indicator {
  transform: translateX(26px);
}

.toggle-switch input:focus + label .toggle-track {
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
}

/* Hover effects */
.toggle-switch:hover label .toggle-track {
  background-color: #bdbdbd;
}

.toggle-switch input:checked:hover + label .toggle-track {
  background-color: #1976D2;
}

/* Topics Grid */
.topics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
  padding: 1rem;
}

/* Topic Card */
.topic-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.topic-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.topic-card-header {
  padding: 1.5rem;
  text-align: center;
}

.topic-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  border: 3px solid #f0f0f0;
}

.topic-title {
  color: #333;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
}

.lang-indicator {
  color: #666;
  font-size: 0.9rem;
}

.topic-card-body {
  padding: 1.5rem;
}

.topic-description {
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.topic-actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.topic-btn {
  padding: 0.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.topic-btn.details { background: #E3F2FD; color: #1976D2; }
.topic-btn.edit { background: #E8F5E9; color: #388E3C; }
.topic-btn.level { background: #F3E5F5; color: #7B1FA2; }
.topic-btn.problems { background: #FFF3E0; color: #E64A19; }

.topic-btn:hover {
  -webkit-filter: brightness(0.95);
          filter: brightness(0.95);
  transform: translateY(-2px);
}

/* Details Section */
.topic-details {
  margin-top: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;  /* Creates two equal columns */
  grid-gap: 1rem;
  gap: 1rem;                       /* Space between grid items */
  padding: 0.5rem;
}

.detail-item {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.detail-label {
  font-weight: 600;
  color: #666;
  font-size: 0.9rem;
}

.detail-value {
  font-size: 1.1rem;
  color: #333;
  font-weight: 500;
}

/* Ensure grid stays 2x2 on smaller screens */
@media (max-width: 768px) {
  .details-grid {
    grid-template-columns: 1fr 1fr;  /* Maintain two columns even on mobile */
    grid-gap: 0.75rem;
    gap: 0.75rem;
    padding: 0.25rem;
  }
}

/* Modern Form */
.modern-form {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
}

.modern-input,
.modern-form input,
.modern-form textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.modern-input:focus,
.modern-form input:focus,
.modern-form textarea:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
}

/* Modal Styles */
.modern-modal {
  border-radius: 15px;
  overflow: hidden;
}

.modern-modal .modal-header {
  background: #f8f9fa;
  border-bottom: none;
  padding: 1.5rem;
}

.modern-modal .modal-body {
  padding: 1.5rem;
}

.modern-modal .modal-footer {
  border-top: none;
  padding: 1.5rem;
}

.btn-submit {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn-cancel {
  background: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn-submit:hover,
.btn-cancel:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Collapsible Sections */
.collapse {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  pointer-events: auto !important;
}

.input-group {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.5rem;
  pointer-events: auto !important;
}

.input-group input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  background: white;
}

.input-group input:focus {
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
  outline: none;
}

.input-group input::-webkit-input-placeholder {
  color: #9e9e9e;
}

.input-group input:-ms-input-placeholder {
  color: #9e9e9e;
}

.input-group input::-ms-input-placeholder {
  color: #9e9e9e;
}

.input-group input::placeholder {
  color: #9e9e9e;
}

.input-group .editable-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  background: white;
  color: #333;
  pointer-events: auto;
  cursor: text;
  margin-bottom: 0.5rem;
}

.input-group .editable-input:focus {
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
  outline: none;
  background: white;
}

.input-group .editable-input:hover {
  border-color: #90CAF9;
  background: #FAFAFA;
}

.input-group .editable-input::-webkit-input-placeholder {
  color: #9e9e9e;
  opacity: 0.8;
}

.input-group .editable-input:-ms-input-placeholder {
  color: #9e9e9e;
  opacity: 0.8;
}

.input-group .editable-input::-ms-input-placeholder {
  color: #9e9e9e;
  opacity: 0.8;
}

.input-group .editable-input::placeholder {
  color: #9e9e9e;
  opacity: 0.8;
}

.input-group .btn-submit {
  background: linear-gradient(45deg, #2196F3, #1976D2);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.input-group .btn-submit:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(33, 150, 243, 0.3);
  background: linear-gradient(45deg, #1976D2, #1565C0);
}

.input-group .btn-submit:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.2);
}

/* Add Level Section Specific Styles */
#collapse2 .input-group {
  background: linear-gradient(135deg, #E3F2FD 0%, #BBDEFB 100%);
  padding: 1.5rem;
  border-radius: 12px;
}

#collapse2 .btn-submit {
  background: linear-gradient(45deg, #673AB7, #5E35B1);
}

#collapse2 .btn-submit:hover {
  background: linear-gradient(45deg, #5E35B1, #512DA8);
  box-shadow: 0 5px 15px rgba(103, 58, 183, 0.3);
}

/* Problems Section Specific Styles */
#collapse3 .input-group {
  background: linear-gradient(135deg, #F3E5F5 0%, #E1BEE7 100%);
  padding: 1.5rem;
  border-radius: 12px;
}

#collapse3 .btn-submit {
  background: linear-gradient(45deg, #E91E63, #D81B60);
}

#collapse3 .btn-submit:hover {
  background: linear-gradient(45deg, #D81B60, #C2185B);
  box-shadow: 0 5px 15px rgba(233, 30, 99, 0.3);
}

/* Problems section specific styles */
.problems-input {
  border: 2px solid #3498db;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 14px;
  width: 200px;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
}

.problems-input:focus {
  border-color: #2980b9;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.4);
  outline: none;
}

.update-problems-btn {
  background: linear-gradient(135deg, #3498db, #2980b9);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.update-problems-btn:hover {
  background: linear-gradient(135deg, #2980b9, #1c638e);
  transform: translateY(-1px);
}

.update-problems-btn i {
  font-size: 16px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .dashboard-content {
    padding: 1rem;
  }
  
  .action-panel {
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .main-actions {
    flex-direction: column;
    width: 100%;
  }
  
  .action-button {
    width: 100%;
    justify-content: center;
  }
  
  .topics-grid {
    grid-template-columns: 1fr;
  }
  
  .topic-actions {
    grid-template-columns: 1fr;
  }
}

/* Dashboard Layout */
.dashboard-container {
  min-height: 100vh;
  background: #f5f6fa;
  font-family: 'Poppins', sans-serif;
}

.dashboard-content {
  padding: 2rem;
}

/* Action Panel */
.action-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.main-actions {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.action-button {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button i {
  font-size: 1.1rem;
}

.action-button.primary {
  background: linear-gradient(45deg, #2196F3, #1976D2);
  color: white;
}

.action-button.secondary {
  background: linear-gradient(45deg, #673AB7, #5E35B1);
  color: white;
}

.action-button.info {
  background: linear-gradient(45deg, #00BCD4, #00ACC1);
  color: white;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Series Grid */
.series-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
  padding: 1rem 0;
}

.series-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.series-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.series-card-header {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  border-bottom: 1px solid #f0f0f0;
}

.series-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.series-title {
  color: #333;
  text-decoration: none;
  margin: 0;
}

.series-title h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.series-card-body {
  padding: 1.5rem;
}

.series-description {
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.series-actions {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.series-btn {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.series-btn.details {
  background: linear-gradient(45deg, #2196F3, #1976D2);
}

.series-btn.edit {
  background: linear-gradient(45deg, #673AB7, #5E35B1);
}

.series-btn.problems {
  background: linear-gradient(45deg, #00BCD4, #00ACC1);
}

.series-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Filter Section */
.filter-section {
  background: white;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.filter-group {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: flex-start;
}

.filter-block {
  flex: 1 1;
}

.filter-label {
  display: block;
  margin-bottom: 8px;
  color: #555;
  font-weight: 500;
  font-size: 0.9rem;
}

.filter-input-container {
  position: relative;
}

.filter-input {
  width: 100%;
  padding: 10px 15px 10px 35px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.filter-input:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
  outline: none;
}

.filter-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 0.9rem;
}

.filter-input::-webkit-input-placeholder {
  color: #999;
}

.filter-input:-ms-input-placeholder {
  color: #999;
}

.filter-input::-ms-input-placeholder {
  color: #999;
}

.filter-input::placeholder {
  color: #999;
}

/* Modern Modal Styles */
.modern-modal {
  border-radius: 12px;
  overflow: hidden;
}

.modern-modal .modal-header {
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
  padding: 1.5rem;
}

.modern-modal .modal-title {
  font-weight: 600;
  color: #333;
}

.modern-modal .modal-body {
  padding: 1.5rem;
}

.modern-modal .form-group {
  margin-bottom: 1.5rem;
}

.modern-modal .form-control {
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  transition: all 0.3s ease;
}

.modern-modal .form-control:focus {
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
}

.modern-modal .modal-footer {
  border-top: none;
  padding: 1.5rem;
}

/* Details Section */
.series-details {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.detail-label {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}

.detail-value {
  font-size: 1.1rem;
  color: #333;
  font-weight: 600;
}

/* Input Groups */
.input-group {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.input-group input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
}

.input-group input:focus {
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
  outline: none;
}

.btn-submit {
  background: linear-gradient(45deg, #2196F3, #1976D2);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.btn-submit:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(33, 150, 243, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .dashboard-content {
    padding: 1rem;
  }
  
  .action-panel {
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .main-actions {
    width: 100%;
    justify-content: center;
  }
  
  .series-grid {
    grid-template-columns: 1fr;
  }
}

.dashboard-container {
  min-height: 100vh;
  background: #f5f5f5;
}

.dashboard-content {
  padding: 2rem;
}

.action-panel {
  margin-bottom: 2rem;
}

.main-actions {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.action-button {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button.primary {
  background: #2196f3;
  color: white;
}

.action-button.info {
  background: #03a9f4;
  color: white;
}

.action-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.tests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 1rem 0;
}

.test-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  padding: 1.5rem;
  transition: transform 0.2s ease;
}

.test-card:hover {
  transform: translateY(-2px);
}

.test-card-header {
  margin-bottom: 1.5rem;
}

.test-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #333;
}

.test-badges {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.level-badge, .status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.level-1 { background: #e3f2fd; color: #1976d2; }
.level-2 { background: #f3e5f5; color: #7b1fa2; }
.level-3 { background: #e8f5e9; color: #388e3c; }

.status-badge.not-live { background: #ffebee; color: #c62828; }
.status-badge.completed { background: #e8f5e9; color: #2e7d32; }
.status-badge.live { background: #e3f2fd; color: #1976d2; }

.test-card-actions {
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.card-btn {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.card-btn.edit {
  background: #2196f3;
  color: white;
}

.card-btn.delete {
  background: #f44336;
  color: white;
}

.card-btn.results {
  background: #4caf50;
  color: white;
}

/* Modal styles */
.modern-modal {
  border-radius: 12px;
  overflow: hidden;
}

.modern-input, .modern-select {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 0.75rem;
  width: 100%;
  margin-bottom: 1rem;
  transition: border-color 0.2s ease;
}

.modern-input:focus, .modern-select:focus {
  border-color: #2196f3;
  outline: none;
}

.modern-select {
  min-height: 45px !important;  /* Increase minimum height */
  height: auto !important;      /* Allow height to grow */
  white-space: normal;          /* Allow text to wrap */
  padding: 10px 12px !important; /* Add more padding */
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group select {
  text-overflow: ellipsis;
  padding-right: 30px !important;  /* Space for dropdown arrow */
}

/* Fix dropdown arrow positioning */
.form-group select:not([multiple]) {
  background-position: right 10px center;
  background-size: 10px;
}

.btn-submit, .btn-cancel {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-submit {
  background: #2196f3;
  color: white;
  border: none;
}

.btn-cancel {
  background: #f5f5f5;
  color: #666;
  border: 1px solid #e0e0e0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
#td{
  vertical-align: middle;
}
.green{
  color:green;
}
.red{
  color:red;
}
.blue{
  color:blue;
}
.search-bar {
  border-radius: 1.5rem; /* Rounded corners for a modern look */
  padding: 10px 20px; /* Padding for a better space around the text */
  font-size: 1rem; /* Clear, readable font size */
  width: 100%; /* Ensure the search bar spans the full width of its container */
  max-width: 400px; /* Set a max width to avoid stretching on large screens */
  margin: 0 auto 20px auto; /* Center the search bar with some margin below */
  background-color: #f8f9fa; /* Light gray background */
  border: 1px solid #ddd; /* Subtle border to differentiate the input field */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: all 0.3s ease; /* Smooth transition on focus */
}

.search-bar:focus {
  outline: none; /* Remove the default outline */
  border-color: #007bff; /* Highlight the border on focus */
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3); /* Focus shadow */
}

.search-bar::-webkit-input-placeholder {
  color: #6c757d; /* Placeholder color */
  font-style: italic; /* Italicize the placeholder for a more refined look */
}

.search-bar:-ms-input-placeholder {
  color: #6c757d; /* Placeholder color */
  font-style: italic; /* Italicize the placeholder for a more refined look */
}

.search-bar::-ms-input-placeholder {
  color: #6c757d; /* Placeholder color */
  font-style: italic; /* Italicize the placeholder for a more refined look */
}

.search-bar::placeholder {
  color: #6c757d; /* Placeholder color */
  font-style: italic; /* Italicize the placeholder for a more refined look */
}
.content{


  display:flex;
  flex-direction: column;
  
  justify-content: center;
  text-align: center;
  align-items: center;

}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card-title{

cursor:pointer;


}
body{
  background-color: #f7f7f7;
}

.card{

  box-shadow: 3px 3px 5px 6px #ccc;

}
.card:hover{

background-color:#FFE5B4;

}
.search{
position:absolute;
right: 0%;
padding-top: 20px;

}
.page-link{
  color:white;
  cursor: pointer;

}
.nav{
  position:absolute;
  right:50%;
  

}

.back{

position:absolute;
left: 0%;
width:50px;

}

.loading-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #282c34;
  perspective: 1000px; /* Add perspective for 3D effect */
}

.loading-logo {
  width: 120px;
  height: 120px;
  -webkit-animation: flip-y 2s infinite ease;
          animation: flip-y 2s infinite ease;
  -webkit-filter: drop-shadow(0 0 10px rgba(97, 218, 251, 0.5));
          filter: drop-shadow(0 0 10px rgba(97, 218, 251, 0.5));
  transform-style: preserve-3d; /* Preserve 3D transformations */
}

.loading-text {
  color: #61dafb;
  font-size: 24px;
  margin-top: 20px;
  font-weight: 300;
  letter-spacing: 2px;
  -webkit-animation: pulse 2s infinite ease;
          animation: pulse 2s infinite ease;
}

@-webkit-keyframes flip-y {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes flip-y {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@-webkit-keyframes pulse-spin {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes pulse-spin {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.add-test-container {
  min-height: 100vh;
  background: #f5f7fa;
}

.test-setup-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.setup-header {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e0e6ed;
}

.setup-header h2 {
  color: #2d3748;
  font-size: 1.8rem;
  font-weight: 600;
}

.level-selection {
  margin-bottom: 2rem;
}

.level-buttons {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1rem;
}

.level-btn {
  padding: 0.75rem 1.5rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #4a5568;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
}

.level-btn:hover {
  border-color: #2196f3;
  color: #2196f3;
}

.level-btn.active {
  background: #2196f3;
  border-color: #2196f3;
  color: white;
}

.topics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-top: 1rem;
}

.topic-card {
  background: white;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  grid-gap: 1rem;
  gap: 1rem;
  transition: all 0.3s ease;
}

.topic-card:hover {
  border-color: #2196f3;
  transform: translateY(-2px);
}

.topic-card.selected {
  background: #e3f2fd;
  border-color: #2196f3;
  background: linear-gradient(145deg, #ffffff, #e6f3ff);
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(33,150,243,0.15);
}

.topic-icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.problem-count {
  font-size: 0.875rem;
  color: #718096;
  margin-top: 0.5rem;
}

.selected-topics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1rem;
}

.count-input-card {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.count-input-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.count-input-wrapper input {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  text-align: center;
}

.max-count {
  font-size: 0.75rem;
  color: #718096;
}

.submit-btn {
  margin-top: 2rem;
  padding: 1rem 2rem;
  background: #2196f3;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.submit-btn:disabled {
  background: #cbd5e0;
  cursor: not-allowed;
}

.loading-spinner {
  text-align: center;
  padding: 2rem;
  color: #718096;
}

.rainbow-btn {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
  background: linear-gradient(45deg, #ff6b6b, #4ecdc4, #45b7d1, #7367f0);
  background-size: 300% 300%;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  -webkit-animation: gradient 5s ease infinite;
          animation: gradient 5s ease infinite;
}

.rainbow-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0,0,0,0.25);
}

.rainbow-btn:disabled {
  background: #cbd5e0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

@-webkit-keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.topic-header {
  cursor: pointer;
}

.problem-count-input {
  padding-top: 0.8rem;
  border-top: 1px solid #e2e8f0;
  -webkit-animation: slideDown 0.3s ease-out;
          animation: slideDown 0.3s ease-out;
  width: 100%;
}

.count-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.count-btn {
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  background: #f0f4f8;
  color: #2196f3;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.count-btn:hover:not(:disabled) {
  background: #2196f3;
  color: white;
  transform: scale(1.1);
}

.count-btn:disabled {
  background: #e2e8f0;
  color: #a0aec0;
  cursor: not-allowed;
}

.problem-count-input input {
  width: 80px;
  padding: 0.5rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  color: #2d3748;
  transition: all 0.2s ease;
}

.problem-count-input input:focus {
  border-color: #2196f3;
  outline: none;
  box-shadow: 0 0 0 3px rgba(33,150,243,0.2);
}

.count-progress {
  position: relative;
  height: 24px;
  background: #f0f4f8;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 0.5rem;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, #2196f3, #64b5f6);
  transition: width 0.3s ease;
  z-index: 1;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 24px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #2d3748;
  z-index: 2;
  text-shadow: 0 0 2px rgba(255,255,255,0.8);
  mix-blend-mode: normal;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 24px;
  color: #2d3748;
  font-size: 0.875rem;
  font-weight: 500;
  mix-blend-mode: difference;
  color: white;
}

@-webkit-keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fetch-topics-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 2rem 0;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.fetch-topics-section h3 {
  margin-bottom: 1.5rem;
  color: #2d3748;
  font-size: 1.4rem;
}

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
}

.login-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('https://images.unsplash.com/photo-1510519138101-570d1dca3d66?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8dGVjaG5vbG9neXx8fHx8fDE2MzQ0MjY5MzQ&ixlib=rb-1.2.1&q=80&w=1080') center/cover;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  opacity: 0.2;
  z-index: 0;
}

.login-container {
  position: relative;
  width: 100%;
  max-width: 420px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 1;
  -webkit-animation: container-appear 0.6s ease-out;
          animation: container-appear 0.6s ease-out;
}

.brand-section {
  text-align: center;
  margin-bottom: 30px;
}

.logo {
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 2px;
  margin-bottom: 8px;
}

.brand-tagline {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  margin: 0;
}

.login-form {
  width: 100%;
}

h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
}

.login-subtitle {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  margin-bottom: 30px;
  font-size: 14px;
}

.form-group {
  margin-bottom: 20px;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
}

.input-group i {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.input-group input {
  width: 100%;
  padding: 12px 40px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  background: #f8f9fa;
  transition: all 0.3s ease;
}

.input-group input::-webkit-input-placeholder {
  color: #999;
}

.input-group input:-ms-input-placeholder {
  color: #999;
}

.input-group input::-ms-input-placeholder {
  color: #999;
}

.input-group input::placeholder {
  color: #999;
}

.input-group input:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
}

.password-toggle {
  position: absolute;
  right: 15px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  padding: 0;
}

.password-toggle:focus {
  outline: none;
}

.login-button {
  width: 100%;
  padding: 12px;
  background: #fff;
  color: #2a5298;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.login-button i {
  font-size: 14px;
  transition: transform 0.3s ease;
}

.login-button:hover i {
  transform: translateX(5px);
}

@-webkit-keyframes container-appear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes container-appear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 480px) {
  .login-container {
    margin: 20px;
    padding: 30px;
  }
  
  .logo {
    font-size: 28px;
  }
  
  h2 {
    font-size: 22px;
  }
}
