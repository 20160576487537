.add-test-container {
  min-height: 100vh;
  background: #f5f7fa;
}

.test-setup-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.setup-header {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e0e6ed;
}

.setup-header h2 {
  color: #2d3748;
  font-size: 1.8rem;
  font-weight: 600;
}

.level-selection {
  margin-bottom: 2rem;
}

.level-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.level-btn {
  padding: 0.75rem 1.5rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #4a5568;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
}

.level-btn:hover {
  border-color: #2196f3;
  color: #2196f3;
}

.level-btn.active {
  background: #2196f3;
  border-color: #2196f3;
  color: white;
}

.topics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.topic-card {
  background: white;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  transition: all 0.3s ease;
}

.topic-card:hover {
  border-color: #2196f3;
  transform: translateY(-2px);
}

.topic-card.selected {
  background: #e3f2fd;
  border-color: #2196f3;
  background: linear-gradient(145deg, #ffffff, #e6f3ff);
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(33,150,243,0.15);
}

.topic-icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.problem-count {
  font-size: 0.875rem;
  color: #718096;
  margin-top: 0.5rem;
}

.selected-topics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.count-input-card {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.count-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.count-input-wrapper input {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  text-align: center;
}

.max-count {
  font-size: 0.75rem;
  color: #718096;
}

.submit-btn {
  margin-top: 2rem;
  padding: 1rem 2rem;
  background: #2196f3;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.submit-btn:disabled {
  background: #cbd5e0;
  cursor: not-allowed;
}

.loading-spinner {
  text-align: center;
  padding: 2rem;
  color: #718096;
}

.rainbow-btn {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
  background: linear-gradient(45deg, #ff6b6b, #4ecdc4, #45b7d1, #7367f0);
  background-size: 300% 300%;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  gap: 0.75rem;
  animation: gradient 5s ease infinite;
}

.rainbow-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0,0,0,0.25);
}

.rainbow-btn:disabled {
  background: #cbd5e0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.topic-header {
  cursor: pointer;
}

.problem-count-input {
  padding-top: 0.8rem;
  border-top: 1px solid #e2e8f0;
  animation: slideDown 0.3s ease-out;
  width: 100%;
}

.count-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.count-btn {
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  background: #f0f4f8;
  color: #2196f3;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.count-btn:hover:not(:disabled) {
  background: #2196f3;
  color: white;
  transform: scale(1.1);
}

.count-btn:disabled {
  background: #e2e8f0;
  color: #a0aec0;
  cursor: not-allowed;
}

.problem-count-input input {
  width: 80px;
  padding: 0.5rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  color: #2d3748;
  transition: all 0.2s ease;
}

.problem-count-input input:focus {
  border-color: #2196f3;
  outline: none;
  box-shadow: 0 0 0 3px rgba(33,150,243,0.2);
}

.count-progress {
  position: relative;
  height: 24px;
  background: #f0f4f8;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 0.5rem;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, #2196f3, #64b5f6);
  transition: width 0.3s ease;
  z-index: 1;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 24px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #2d3748;
  z-index: 2;
  text-shadow: 0 0 2px rgba(255,255,255,0.8);
  mix-blend-mode: normal;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 24px;
  color: #2d3748;
  font-size: 0.875rem;
  font-weight: 500;
  mix-blend-mode: difference;
  color: white;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fetch-topics-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 2rem 0;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.fetch-topics-section h3 {
  margin-bottom: 1.5rem;
  color: #2d3748;
  font-size: 1.4rem;
}
