@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

/* Dashboard Layout */
.dashboard-container {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
}

.dashboard-content {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

/* Action Panel */
.action-panel {
  display: flex;
  justify-content: space-betwenen;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(4px);
  margin-bottom: 2rem;
}

.main-actions {
  display: flex;
  gap: 1rem;
}

.action-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.action-button i {
  font-size: 1.1rem;
}

.action-button.primary {
  background: #4CAF50;
  color: white;
}

.action-button.secondary {
  background: #2196F3;
  color: white;
}

.action-button.info {
  background: #9C27B0;
  color: white;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Language Toggle */
.language-toggle {
  position: relative;
}

.toggle-switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.toggle-switch-label {
  font-size: 0.95rem;
  color: #333;
  margin-bottom: 0;
  font-weight: 500;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-left: 200px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  margin: 0;
}

.toggle-track {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e0e0e0;
  border-radius: 24px;
  transition: background-color 0.3s ease;
}

.toggle-indicator {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.toggle-switch input:checked + label .toggle-track {
  background-color: #2196F3;
}

.toggle-switch input:checked + label .toggle-indicator {
  transform: translateX(26px);
}

.toggle-switch input:focus + label .toggle-track {
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
}

/* Hover effects */
.toggle-switch:hover label .toggle-track {
  background-color: #bdbdbd;
}

.toggle-switch input:checked:hover + label .toggle-track {
  background-color: #1976D2;
}

/* Topics Grid */
.topics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

/* Topic Card */
.topic-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.topic-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.topic-card-header {
  padding: 1.5rem;
  text-align: center;
}

.topic-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  border: 3px solid #f0f0f0;
}

.topic-title {
  color: #333;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
}

.lang-indicator {
  color: #666;
  font-size: 0.9rem;
}

.topic-card-body {
  padding: 1.5rem;
}

.topic-description {
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.topic-actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
}

.topic-btn {
  padding: 0.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.topic-btn.details { background: #E3F2FD; color: #1976D2; }
.topic-btn.edit { background: #E8F5E9; color: #388E3C; }
.topic-btn.level { background: #F3E5F5; color: #7B1FA2; }
.topic-btn.problems { background: #FFF3E0; color: #E64A19; }

.topic-btn:hover {
  filter: brightness(0.95);
  transform: translateY(-2px);
}

/* Details Section */
.topic-details {
  margin-top: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;  /* Creates two equal columns */
  gap: 1rem;                       /* Space between grid items */
  padding: 0.5rem;
}

.detail-item {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detail-label {
  font-weight: 600;
  color: #666;
  font-size: 0.9rem;
}

.detail-value {
  font-size: 1.1rem;
  color: #333;
  font-weight: 500;
}

/* Ensure grid stays 2x2 on smaller screens */
@media (max-width: 768px) {
  .details-grid {
    grid-template-columns: 1fr 1fr;  /* Maintain two columns even on mobile */
    gap: 0.75rem;
    padding: 0.25rem;
  }
}

/* Modern Form */
.modern-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
}

.modern-input,
.modern-form input,
.modern-form textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.modern-input:focus,
.modern-form input:focus,
.modern-form textarea:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
}

/* Modal Styles */
.modern-modal {
  border-radius: 15px;
  overflow: hidden;
}

.modern-modal .modal-header {
  background: #f8f9fa;
  border-bottom: none;
  padding: 1.5rem;
}

.modern-modal .modal-body {
  padding: 1.5rem;
}

.modern-modal .modal-footer {
  border-top: none;
  padding: 1.5rem;
}

.btn-submit {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn-cancel {
  background: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn-submit:hover,
.btn-cancel:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Collapsible Sections */
.collapse {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  pointer-events: auto !important;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  pointer-events: auto !important;
}

.input-group input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  background: white;
}

.input-group input:focus {
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
  outline: none;
}

.input-group input::placeholder {
  color: #9e9e9e;
}

.input-group .editable-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  background: white;
  color: #333;
  pointer-events: auto;
  cursor: text;
  margin-bottom: 0.5rem;
}

.input-group .editable-input:focus {
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
  outline: none;
  background: white;
}

.input-group .editable-input:hover {
  border-color: #90CAF9;
  background: #FAFAFA;
}

.input-group .editable-input::placeholder {
  color: #9e9e9e;
  opacity: 0.8;
}

.input-group .btn-submit {
  background: linear-gradient(45deg, #2196F3, #1976D2);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.input-group .btn-submit:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(33, 150, 243, 0.3);
  background: linear-gradient(45deg, #1976D2, #1565C0);
}

.input-group .btn-submit:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.2);
}

/* Add Level Section Specific Styles */
#collapse2 .input-group {
  background: linear-gradient(135deg, #E3F2FD 0%, #BBDEFB 100%);
  padding: 1.5rem;
  border-radius: 12px;
}

#collapse2 .btn-submit {
  background: linear-gradient(45deg, #673AB7, #5E35B1);
}

#collapse2 .btn-submit:hover {
  background: linear-gradient(45deg, #5E35B1, #512DA8);
  box-shadow: 0 5px 15px rgba(103, 58, 183, 0.3);
}

/* Problems Section Specific Styles */
#collapse3 .input-group {
  background: linear-gradient(135deg, #F3E5F5 0%, #E1BEE7 100%);
  padding: 1.5rem;
  border-radius: 12px;
}

#collapse3 .btn-submit {
  background: linear-gradient(45deg, #E91E63, #D81B60);
}

#collapse3 .btn-submit:hover {
  background: linear-gradient(45deg, #D81B60, #C2185B);
  box-shadow: 0 5px 15px rgba(233, 30, 99, 0.3);
}

/* Problems section specific styles */
.problems-input {
  border: 2px solid #3498db;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 14px;
  width: 200px;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
}

.problems-input:focus {
  border-color: #2980b9;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.4);
  outline: none;
}

.update-problems-btn {
  background: linear-gradient(135deg, #3498db, #2980b9);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.update-problems-btn:hover {
  background: linear-gradient(135deg, #2980b9, #1c638e);
  transform: translateY(-1px);
}

.update-problems-btn i {
  font-size: 16px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .dashboard-content {
    padding: 1rem;
  }
  
  .action-panel {
    flex-direction: column;
    gap: 1rem;
  }
  
  .main-actions {
    flex-direction: column;
    width: 100%;
  }
  
  .action-button {
    width: 100%;
    justify-content: center;
  }
  
  .topics-grid {
    grid-template-columns: 1fr;
  }
  
  .topic-actions {
    grid-template-columns: 1fr;
  }
}
