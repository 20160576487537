.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#td{
  vertical-align: middle;
}
.green{
  color:green;
}
.red{
  color:red;
}
.blue{
  color:blue;
}
.search-bar {
  border-radius: 1.5rem; /* Rounded corners for a modern look */
  padding: 10px 20px; /* Padding for a better space around the text */
  font-size: 1rem; /* Clear, readable font size */
  width: 100%; /* Ensure the search bar spans the full width of its container */
  max-width: 400px; /* Set a max width to avoid stretching on large screens */
  margin: 0 auto 20px auto; /* Center the search bar with some margin below */
  background-color: #f8f9fa; /* Light gray background */
  border: 1px solid #ddd; /* Subtle border to differentiate the input field */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: all 0.3s ease; /* Smooth transition on focus */
}

.search-bar:focus {
  outline: none; /* Remove the default outline */
  border-color: #007bff; /* Highlight the border on focus */
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3); /* Focus shadow */
}

.search-bar::placeholder {
  color: #6c757d; /* Placeholder color */
  font-style: italic; /* Italicize the placeholder for a more refined look */
}
.content{


  display:flex;
  flex-direction: column;
  
  justify-content: center;
  text-align: center;
  align-items: center;

}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card-title{

cursor:pointer;


}
body{
  background-color: #f7f7f7;
}

.card{

  box-shadow: 3px 3px 5px 6px #ccc;

}
.card:hover{

background-color:#FFE5B4;

}
.search{
position:absolute;
right: 0%;
padding-top: 20px;

}
.page-link{
  color:white;
  cursor: pointer;

}
.nav{
  position:absolute;
  right:50%;
  

}

.back{

position:absolute;
left: 0%;
width:50px;

}

.loading-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #282c34;
  perspective: 1000px; /* Add perspective for 3D effect */
}

.loading-logo {
  width: 120px;
  height: 120px;
  animation: flip-y 2s infinite ease;
  filter: drop-shadow(0 0 10px rgba(97, 218, 251, 0.5));
  transform-style: preserve-3d; /* Preserve 3D transformations */
}

.loading-text {
  color: #61dafb;
  font-size: 24px;
  margin-top: 20px;
  font-weight: 300;
  letter-spacing: 2px;
  animation: pulse 2s infinite ease;
}

@keyframes flip-y {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes pulse-spin {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
