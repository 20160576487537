@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

/* Dashboard Layout */
.dashboard-container {
  min-height: 100vh;
  background: #f5f6fa;
  font-family: 'Poppins', sans-serif;
}

.dashboard-content {
  padding: 2rem;
}

/* Action Panel */
.action-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.main-actions {
  display: flex;
  gap: 1rem;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button i {
  font-size: 1.1rem;
}

.action-button.primary {
  background: linear-gradient(45deg, #2196F3, #1976D2);
  color: white;
}

.action-button.secondary {
  background: linear-gradient(45deg, #673AB7, #5E35B1);
  color: white;
}

.action-button.info {
  background: linear-gradient(45deg, #00BCD4, #00ACC1);
  color: white;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Series Grid */
.series-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem 0;
}

.series-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.series-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.series-card-header {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #f0f0f0;
}

.series-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.series-title {
  color: #333;
  text-decoration: none;
  margin: 0;
}

.series-title h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.series-card-body {
  padding: 1.5rem;
}

.series-description {
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.series-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.series-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.series-btn.details {
  background: linear-gradient(45deg, #2196F3, #1976D2);
}

.series-btn.edit {
  background: linear-gradient(45deg, #673AB7, #5E35B1);
}

.series-btn.problems {
  background: linear-gradient(45deg, #00BCD4, #00ACC1);
}

.series-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Filter Section */
.filter-section {
  background: white;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.filter-group {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.filter-block {
  flex: 1;
}

.filter-label {
  display: block;
  margin-bottom: 8px;
  color: #555;
  font-weight: 500;
  font-size: 0.9rem;
}

.filter-input-container {
  position: relative;
}

.filter-input {
  width: 100%;
  padding: 10px 15px 10px 35px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.filter-input:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
  outline: none;
}

.filter-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 0.9rem;
}

.filter-input::placeholder {
  color: #999;
}

/* Modern Modal Styles */
.modern-modal {
  border-radius: 12px;
  overflow: hidden;
}

.modern-modal .modal-header {
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
  padding: 1.5rem;
}

.modern-modal .modal-title {
  font-weight: 600;
  color: #333;
}

.modern-modal .modal-body {
  padding: 1.5rem;
}

.modern-modal .form-group {
  margin-bottom: 1.5rem;
}

.modern-modal .form-control {
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  transition: all 0.3s ease;
}

.modern-modal .form-control:focus {
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
}

.modern-modal .modal-footer {
  border-top: none;
  padding: 1.5rem;
}

/* Details Section */
.series-details {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detail-label {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}

.detail-value {
  font-size: 1.1rem;
  color: #333;
  font-weight: 600;
}

/* Input Groups */
.input-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.input-group input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
}

.input-group input:focus {
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
  outline: none;
}

.btn-submit {
  background: linear-gradient(45deg, #2196F3, #1976D2);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.btn-submit:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(33, 150, 243, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .dashboard-content {
    padding: 1rem;
  }
  
  .action-panel {
    flex-direction: column;
    gap: 1rem;
  }
  
  .main-actions {
    width: 100%;
    justify-content: center;
  }
  
  .series-grid {
    grid-template-columns: 1fr;
  }
}
