.problems-container {
  padding: 20px;
}

.problems-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

/* Problem Card Styles */
.problem-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 400px;
}

.problem-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.card-image-wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background: #f8f9fa;
  overflow: hidden;
}

.problem-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.problem-card:hover .problem-image {
  transform: scale(1.05);
}

.card-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
}

.difficulty-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.difficulty-badge.easy { background: #28a745; color: white; }
.difficulty-badge.medium { background: #ffc107; color: #212529; }
.difficulty-badge.hard { background: #dc3545; color: white; }

.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
}

.status-badge.pending {
  background: #ff9800;
  color: white;
}

.card-content {
  padding: 1.5rem;
}

.card-header {
  margin-bottom: 12px;
}

.card-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px 0;
  color: #2c3e50;
}

.problem-id {
  color: #6c757d;
  font-size: 12px;
}

.card-meta {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.setter-name {
  font-size: 14px;
  color: #495057;
  display: flex;
  align-items: center;
  gap: 6px;
}

.setter-name i {
  color: #6c757d;
  font-size: 12px;
}

.card-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.primary-actions, 
.secondary-actions {
  display: flex;
  gap: 8px;
}

.card-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
}

.card-btn i {
  font-size: 14px;
}

.card-btn.info { background: #E3F2FD; color: #1976D2; }
.card-btn.edit { background: #E8F5E9; color: #388E3C; }
.card-btn.view { background: #F3E5F5; color: #7B1FA2; }
.card-btn.delete { background: #FFEBEE; color: #D32F2F; }
.card-btn.approve { background: #E8F5E9; color: #388E3C; }
.card-btn.map { background: #FFF3E0; color: #E64A19; }

.card-btn:hover {
  transform: translateY(-2px);
  filter: brightness(0.95);
}

/* Collapse Panels */
.collapse-panel {
  margin-top: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .problems-grid {
    grid-template-columns: 1fr;
  }
  
  .card-actions {
    flex-direction: column;
  }
  
  .primary-actions,
  .secondary-actions {
    flex-wrap: wrap;
  }
}
