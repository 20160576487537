.dashboard-container {
  min-height: 100vh;
  background: #f5f5f5;
}

.dashboard-content {
  padding: 2rem;
}

.action-panel {
  margin-bottom: 2rem;
}

.main-actions {
  display: flex;
  gap: 1rem;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button.primary {
  background: #2196f3;
  color: white;
}

.action-button.info {
  background: #03a9f4;
  color: white;
}

.action-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.tests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem 0;
}

.test-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  padding: 1.5rem;
  transition: transform 0.2s ease;
}

.test-card:hover {
  transform: translateY(-2px);
}

.test-card-header {
  margin-bottom: 1.5rem;
}

.test-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #333;
}

.test-badges {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.level-badge, .status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.level-1 { background: #e3f2fd; color: #1976d2; }
.level-2 { background: #f3e5f5; color: #7b1fa2; }
.level-3 { background: #e8f5e9; color: #388e3c; }

.status-badge.not-live { background: #ffebee; color: #c62828; }
.status-badge.completed { background: #e8f5e9; color: #2e7d32; }
.status-badge.live { background: #e3f2fd; color: #1976d2; }

.test-card-actions {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.card-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.card-btn.edit {
  background: #2196f3;
  color: white;
}

.card-btn.delete {
  background: #f44336;
  color: white;
}

.card-btn.results {
  background: #4caf50;
  color: white;
}

/* Modal styles */
.modern-modal {
  border-radius: 12px;
  overflow: hidden;
}

.modern-input, .modern-select {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 0.75rem;
  width: 100%;
  margin-bottom: 1rem;
  transition: border-color 0.2s ease;
}

.modern-input:focus, .modern-select:focus {
  border-color: #2196f3;
  outline: none;
}

.modern-select {
  min-height: 45px !important;  /* Increase minimum height */
  height: auto !important;      /* Allow height to grow */
  white-space: normal;          /* Allow text to wrap */
  padding: 10px 12px !important; /* Add more padding */
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group select {
  text-overflow: ellipsis;
  padding-right: 30px !important;  /* Space for dropdown arrow */
}

/* Fix dropdown arrow positioning */
.form-group select:not([multiple]) {
  background-position: right 10px center;
  background-size: 10px;
}

.btn-submit, .btn-cancel {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-submit {
  background: #2196f3;
  color: white;
  border: none;
}

.btn-cancel {
  background: #f5f5f5;
  color: #666;
  border: 1px solid #e0e0e0;
}
